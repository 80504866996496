// 规则状态
const ruleStatus = [
  {
    value: 0,
    label: "关闭",
  },
  {
    value: 1,
    label: "开启",
  },
];
// 规则类型
const ruleType = [
  {
    value: "单一规则",
    name: 0,
  },
  {
    value: "组合规则",
    name: 1,
  },
];
// 校验方式
const checkMode = [
  {
    value: "顺序校验",
    name: "0",
  },
  {
    value: "组合校验",
    name: "1",
  },
];
// 告警类型
const notifyType = [
  {
    value: "0",
    label: "提示",
  },
  {
    value: "1",
    label: "告警",
  },
  {
    value: "2",
    label: "故障",
  },
];
// 通知方式
const notifyMode = [
  {
    value: 0,
    label: "短信",
  },
  {
    value: 1,
    label: "工单",
  },
  {
    value: 2,
    label: "短信/工单",
  },
];
// 规则关系
const ruleRelationship = [
  {
    value: "_or_",
    label: "or",
  },
  {
    value: "_and_",
    label: "and",
  },
];

export {
  ruleStatus,
  ruleType,
  checkMode,
  notifyType,
  notifyMode,
  ruleRelationship,
};
